import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
    state: {

      loading: false,
      error: null
    },

    getters: {

    },
    mutations: {

    },

  actions: {
      saveProductPriceList({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.saveProductPriceList(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      editProductPriceList({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.editProductPriceList(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getProductPriceList({commit}){
          return new Promise((resolve,reject) => {
              httpService.getProductPriceList()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      editProduct({commit}, data) {
              return new Promise((resolve,reject) => {

                httpService.editProduct(data)
                  .then(response => {

                    // If there's user data in response
                    if(response.data.statusCode == 200){

                      resolve()

                      //this.dispatch('campaigns/getCampaigns')

                    }
                    else{
                      reject({message: response.data.message})
                    }

                  })
                  .catch(error => { reject(error)

                    //reject({message: axios.defaults.headers})
                  })
              })
    },
      saveProduct({commit}, data) {
          return new Promise((resolve,reject) => {

            httpService.saveProduct(data)
              .then(response => {

                // If there's user data in response
                if(response.data.statusCode == 200){

                  resolve()

                  //this.dispatch('campaigns/getCampaigns')

                }
                else{
                  reject({message: response.data.message})
                }

              })
              .catch(error => { reject(error)

                //reject({message: axios.defaults.headers})
              })
          })
    },



      deleteProduct({commit}, data) {
          return new Promise((resolve,reject) => {

            httpService.deleteProduct(data.id)
              .then(response => {

                // If there's user data in response
                if(response.data.statusCode == 200){

                  resolve()

                  //this.dispatch('campaigns/getCampaigns')

                }
                else{
                  reject({message: response.data.message})
                }

              })
              .catch(error => { reject(error)

                //reject({message: axios.defaults.headers})
              })
          })
    },

      getProducts({commit}){

          return new Promise((resolve,reject) => {
            httpService.getProducts()
              .then(response => {

                resolve(response.data);

              })
              .catch(error => {
                reject(error)

                //reject({message: axios.defaults.headers})
              })
          })
    },
      editProductFinishes({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.editProductFinishes(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      saveProductFinishes({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.saveProductFinishes(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },



      deleteProductFinishes({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.deleteProductFinishes(data.id)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getProductFinishes({commit}){

          return new Promise((resolve,reject) => {
              httpService.getProductFinishes()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getInventoryItems({commit}){
          return new Promise((resolve,reject) => {
              httpService.getInventoryItems()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      saveItemGroup({commit}, data) {
          return new Promise((resolve,reject) => {

              httpService.saveItemGroup(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      editItemGroup({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.editItemGroup(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      }



  }
};
