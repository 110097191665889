import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {

    importOutlets({commit}, customersData){
      return new Promise((resolve,reject) => {
        httpService.importOutlets(customersData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }



          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      generateAllMerchantInvoiceForThePreviousMonth({commit}, outletData){
          return new Promise((resolve,reject) => {
              httpService.generateAllMerchantInvoiceForThePreviousMonth(outletData.id, outletData.transactionType)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve()
                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

    getOutlets({commit}){

      return new Promise((resolve,reject) => {
        httpService.getOutlets()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });


    },

    getOutlet({commit}, data){
      return new Promise((resolve,reject) => {

        httpService.getOutletDetails(data)
          .then(response => {

            resolve(response.data)

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },

    searchOutletsByName({commit}, data){
      return new Promise((resolve,reject) => {

        httpService.searchOutletsByName(data.name)
          .then(response => {

            resolve(response.data)

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    addOutlet({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.addOutlet(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    generateInvoice({commit}, data){
      return new Promise((resolve,reject) => {

        httpService.generateInvoice(data.start_date, data.end_date, data.outletId)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve();

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    getInvoices({commit}, paginationData){
      return new Promise((resolve,reject) => {
          console.log(paginationData)
        httpService.getInvoices(paginationData.page)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    deleteOutlet({commit}, data) {
      return new Promise((resolve,reject) => {

        httpService.deleteOutlet(data)
          .then(response => {

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    editOutlet({commit}, data){
      return new Promise((resolve,reject) => {

        httpService.editOutlet(data)
          .then(response => {

            console.log(response.data);

            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    updateOutletStatus({commit}, data){
      return new Promise((resolve,reject) => {

        httpService.updateOutletStatus(data)
          .then(response => {


            // If there's user data in response
            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    getOutletsByUserId({commit}, data){
      return new Promise((resolve,reject) => {
        httpService.getOutletsByUserId(data.userId)
          .then(response => {
            resolve(response.data)

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    getInvoiceByOutletId({commit}, data){
      return new Promise((resolve,reject) => {
        httpService.getOutletInvoices(data.outletId)
          .then(response => {
            resolve(response.data)

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    getAllMerchantInvoices({commit}, data){
      return new Promise((resolve,reject) => {
        httpService.getOutletInvoices(data)
          .then(response => {
            resolve(response.data)

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
    getInvoiceById({commit}, data){
      return new Promise((resolve,reject) => {
        httpService.getInvoiceById(data.invoiceId)
          .then(response => {
            resolve(response.data)

          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
      getInvoiceItemsByInvoice({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.getInvoiceItemsByInvoice(data.invoiceId, data.page)
                  .then(response => {
                      resolve(response.data)

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      }
  }
};
