import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },

    actions: {
        forgotPassword({commit}, data) {
            return new Promise((resolve,reject) => {

                httpService.forgotPassword(data)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.statusCode == 200){

                            resolve()

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        addUser({commit}, data) {
            return new Promise((resolve,reject) => {

                httpService.saveUser(data)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.statusCode == 200){

                            resolve()

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        editUser({commit}, data) {
            return new Promise((resolve,reject) => {

                httpService.editUser(data)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.statusCode == 200){

                            resolve()

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },




        getUsers({commit}){

            return new Promise((resolve,reject) => {
                httpService.getUsers()
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        getUsersByRole({commit}, data){

            return new Promise((resolve,reject) => {
                httpService.getUsersByRole(data.role)
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        }



    }
};
