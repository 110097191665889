import httpService from "../../http/requests/app"


export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {

    getLoyaltyTransactions({commit}, filterData){

      return new Promise((resolve,reject) => {
        httpService.getTransactions(filterData)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      downloadInvoiceStatement({commit}, invoiceData){
          return new Promise((resolve,reject) => {
              httpService.downloadInvoiceStatement(invoiceData.invoiceId)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      getOutletPerformance({commit}, filterData){
          return new Promise((resolve,reject) => {
              httpService.getOutletPerformance(filterData.startDate, filterData.endDate, filterData.transactionType)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      getCustomerPerformance({commit}, filterData) {
          return new Promise((resolve,reject) => {
              httpService.getCustomerPerformance(filterData.startDate, filterData.endDate, filterData.transactionType, filterData.limit)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      getLoyaltyQuickStats({commit}){
          return new Promise((resolve,reject) => {
              httpService.getLoyaltyQuickStats()
                  .then(response => {
                      resolve(response.data);
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

    getParkingTransactions({commit}, filterData){

      return new Promise((resolve,reject) => {
        httpService.getParkingTransactions(filterData)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });

    },

    exportParkingTransactions({commit}, filterData){
      return new Promise((resolve,reject) => {
        httpService.exportParkingTransactions(filterData)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },



    exportTransactionsData({commit}, filterData){
      return new Promise((resolve,reject) => {
        httpService.exportTransactionsData(filterData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve();

            }
            else{
              reject({message: response.data.message})
            }



          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    getCustomerTransactions({commit}, filterData){
      return new Promise((resolve,reject) => {
        httpService.getCustomerTransactions(filterData)
          .then(response => {


            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    getLotteryTransactions({commit}, filterData){

      return new Promise((resolve,reject) => {
        httpService.getLotteryTransactions(filterData)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });

    },
    getCustomerDetailsByPhoneNumber({commit}, customerData){
      return new Promise((resolve,reject) => {
        httpService.getCustomerDetailsByPhoneNumber(customerData.phoneNumber)
            .then(response => {

              resolve(response.data);


            })
            .catch(error => {

              //reject({message: axios.defaults.headers})
            })
      });
    },
    getLoyaltyStats({commit}){

      return new Promise((resolve,reject) => {
        httpService.getLoyaltyStats()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });

    },
    getMerchantStats({commit}, outletData){
      return new Promise((resolve,reject) => {
        httpService.getMerchantStats(outletData.id)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
    getLotteryStats({commit}){

      return new Promise((resolve,reject) => {
        httpService.getLotteryStats()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });

    },
    awardCustomerPointsWebPortal({commit}, payloadData){
      return new Promise((resolve,reject) => {
        httpService.awardPoints(payloadData)
            .then(response => {

              if(response.data.statusCode == 200){

                resolve()

              }
              else{
                reject({message: response.data.message})
              }


            })
            .catch(error => {

              //reject({message: axios.defaults.headers})
            })
      });
    },

      clearInvoices({commit}){
          return new Promise((resolve,reject) => {
              httpService.clearInvoices()
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                      }
                      else{
                          reject({message: response.data.message})
                      }


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      generateBulkInvoice({commit}, payload){
          return new Promise((resolve,reject) => {
              httpService.generateBulkInvoice(payload.transactionType)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                      }
                      else{
                          reject({message: response.data.message})
                      }


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      exportInvoices({commit}, payload){
          return new Promise((resolve,reject) => {
              httpService.exportInvoices(payload.startDate, payload.endDate)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve(response.data)
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      resendNetsuiteInvoice({commit}, payload){
          return new Promise((resolve,reject) => {
              httpService.resendNetsuiteInvoice(payload.id)
                  .then(response => {

                      if(response.data.statusCode == 200){
                          resolve(response.data)
                      }
                      else{
                          reject({message: response.data.message})
                      }
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

      redeemPointsWebPortal({commit}, payloadData){
          return new Promise((resolve,reject) => {
              httpService.redeemPoints(payloadData)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                      }
                      else{
                          reject({message: response.data.message})
                      }


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },

    reverseTransaction({commit}, payloadData){
      return new Promise((resolve,reject) => {
        httpService.reverseTransaction(payloadData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

            }
            else{
              reject({message: response.data.message})
            }


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    }
  }
};
