import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },

    actions: {
        addLead({commit}, data) {
            return new Promise((resolve,reject) => {

                httpService.addLead(data)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.statusCode == 200){

                            resolve()

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        editLead({commit}, data) {
            return new Promise((resolve,reject) => {

                httpService.editLead(data)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.statusCode == 200){

                            resolve()

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        deleteLead({commit}, data) {
            return new Promise((resolve,reject) => {

                httpService.deleteLead(data.id)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.statusCode == 200){

                            resolve()

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        updateLeadStatus({commit}, leadSourceData){
            return new Promise((resolve,reject) => {

                httpService.updateLeadStatus(leadSourceData)
                    .then(response => {

                        // If there's user data in response
                        if(response.data.statusCode == 200){

                            resolve()

                            //this.dispatch('campaigns/getCampaigns')

                        }
                        else{
                            reject({message: response.data.message})
                        }

                    })
                    .catch(error => { reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

        getLeads({commit},data){
            return new Promise((resolve,reject) => {
                httpService.getLeads(data.startDate, data.endDate, data.leadStatus, data.leadSource, data.responsible, data.levelOfInterest, data.page)
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },
        getLeadStats({commit},data){
            return new Promise((resolve,reject) => {
                httpService.getLeadStats(data.startDate, data.endDate)
                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        }



    }
};
