import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {
      getVouchers({commit},data) {

      return new Promise((resolve,reject) => {

        httpService.getVouchers(data.page, data.from_date,data.to_date, data.status)
          .then(response => {

            resolve(response.data);


          })
          .catch(error => { reject(error)

            //reject({message: axios.defaults.headers})
          })
      })
    },
      getOutletVoucherCodes({commit},data) {
          return new Promise((resolve,reject) => {

              httpService.getOutletVoucherCodes(data.page,data.outletId, data.fromDate,data.toDate, data.status)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      importVoucherCodes({commit}, voucherCodeData){
          return new Promise((resolve,reject) => {
              httpService.importVoucherCodes(voucherCodeData)
                  .then(response => {

                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }



                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      getVoucherStats({commit},data){
          return new Promise((resolve,reject) => {

              httpService.getVoucherStats(data.from_date,data.to_date, data.status)
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      }
  }
};
