import httpService from "../../http/requests/app"


export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {
      getShortCodeRecipients({commit}){
      return new Promise((resolve,reject) => {
        httpService.getShortCodeRecipients()
          .then(response => {

            resolve(response.data);

          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    }
  }
};
