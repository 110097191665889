import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
    state: {

      loading: false,
      error: null,
        locations:{
            id:0,
            locationName:"",
            county:""
        }
    },

    getters: {

    },
    mutations: {

    },

  actions: {
      getSalesStats({commit}){
          return new Promise((resolve,reject) => {
              httpService.getSalesStats()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getOrderStats({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.getOrderStats(data.startDate, data.endDate)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getQuotationStats({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.getQuotationStats(data.startDate, data.endDate)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getTeamPerformance({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.getTeamPerformance(data.startDate, data.endDate)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getPettyCashBudgets(){
          return new Promise((resolve,reject) => {
              httpService.getPettyCashBudgets()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      createPettyCashBudget({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.createPettyCashBudget(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getPettyCash({commit},data){
          return new Promise((resolve,reject) => {
              httpService.getPettyCash(data.pettyCashStatus,data.startDate,data.endDate,data.supplierId, data.page)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      editPettyCash({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.editPettyCash(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      createPettyCash({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.createPettyCash(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      updatePettyCashStatus({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.updatePettyCashStatus(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getQuotationDetails({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.getQuotationDetails(data.id)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getBankTransactions({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.getBankTransactions(data.startDate, data.endDate, data.page)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      searchBankTransactions({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.searchBankTransactions(data.keyword)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getTaxRates(){
          return new Promise((resolve,reject) => {
              httpService.getTaxRates()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getQuotations({commit},data){
          return new Promise((resolve,reject) => {
              httpService.getQuotations(data.page, data.quotationStatus, data.startDate, data.endDate)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getSalesOrders({commit},data){
          return new Promise((resolve,reject) => {
              httpService.getSalesOrders( data.orderStatus, data.orderDate, data.deliveryDate, data.page)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getInvoices({commit}){
          return new Promise((resolve,reject) => {
              httpService.getInvoices()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      searchQuotationsByCustomerName({commit},data){
          return new Promise((resolve,reject) => {
              httpService.searchQuotationsByCustomerName(data.keyword)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      updateBankTransaction({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.updateBankTransaction(data.id)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      updateSalesOrder({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.updateSalesOrder(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      createQuotation({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.createQuotation(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      receivePayment({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.receivePayment(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      editQuotation({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.editQuotation(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      createInvoiceFromQuotation({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.createInvoiceFromQuotation(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      deleteQuotation({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.deleteQuotation(data.id)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getMpesatransactions({commit}, data){

          return new Promise((resolve,reject) => {
              httpService.getMpesaTransactions(data.startDate, data.endDate, data.page)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      searchMpesaTransactions({commit}, payload){
          return new Promise((resolve,reject) => {
              httpService.searchMpesaTransactions(payload.transId)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      console.log(error);

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      initiateSTKPush({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.initiateSTKPush(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      createSupplier({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.createSupplier(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      editSupplier({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.editSupplier(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
    deleteSupplier({commit}, data){
        return new Promise((resolve,reject) => {

            httpService.deleteSupplier(data.id)
                .then(response => {

                    // If there's user data in response
                    if(response.data.statusCode == 200){

                        resolve()

                        //this.dispatch('campaigns/getCampaigns')

                    }
                    else{
                        reject({message: response.data.message})
                    }

                })
                .catch(error => { reject(error)

                    //reject({message: axios.defaults.headers})
                })
        })
    },
      getSuppliers({commit}){
          return new Promise((resolve,reject) => {
              httpService.getSuppliers()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      console.log(error);

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getPettyCashSuppliers({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.getPettyCashSuppliers(data.supplierId)
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      console.log(error);

                      //reject({message: axios.defaults.headers})
                  })
          })
      }

  }
};
