export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },

  // *===============================================---*
  // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
  // *===============================================---*
  {
    path: '/apps/finance',
    name: 'finance',
    component: () => import('@/views/apps/pages/finance.vue')
  },
  {
    path: '/apps/CreateQuotation',
    name: 'create-quotation',
    component: () => import('@/views/apps/pages/CreateQuotation.vue')
  },

  {
    path: '/apps/quotation/:id',
    name: 'loyalty-import-outlet',
    component: () => import('@/views/apps/pages/QuotationDetails.vue')
  },
  {
    path: '/apps/production',
    name: 'production',
    component: () => import('@/views/apps/pages/production.vue')
  },
  {
    path: '/apps/crm',
    name: 'crm',
    component: () => import('@/views/apps/pages/crm.vue')
  },
  {
    path: '/apps/catalogue',
    name: 'catalogue',
    component: () => import('@/views/apps/pages/catalogue.vue')
  },
  {
    path: '/apps/reports',
    name: 'reports',
    component: () => import('@/views/apps/pages/reports.vue')
  },
  {
    path: '/apps/customers',
    name: 'customers',
    component: () => import('@/views/apps/pages/customers.vue')
  },
  {
    path: '/apps/dailySalesReport',
    name: 'daily-sales-report',
    component: () => import('@/views/apps/pages/dailySalesReports.vue')
  },
  {
    path: '/apps/weeklySalesReport',
    name: 'weekly-sales-report',
    component: () => import('@/views/apps/pages/weeklySalesReports.vue')
  },
  {
    path: '/apps/monthlySalesReport',
    name: 'monthly-sales-report',
    component: () => import('@/views/apps/pages/monthlySalesReports.vue')
  },
  {
    path: '/apps/sms',
    name: 'campaign-sms',
    component: () => import('@/views/apps/pages/campaigns.vue')
  },
  {
    path: '/apps/shortCode',
    name: 'campaign-short-code',
    component: () => import('@/views/apps/pages/shortCode.vue')
  },
  {
    path: '/apps/pushNotifications',
    name: 'campaign-push-notification',
    component: () => import('@/views/apps/pages/campaignPushNotification.vue')
  },


  {
    path: '/apps/createPermissions',
    name: 'loyalty-create-permissions',
    component: () => import('@/views/apps/pages/CreatePermissions.vue')
  },
  {
    path: '/apps/editRole/:id',
    name: 'loyalty-import-outlet',
    component: () => import('@/views/apps/pages/editPermissions.vue')
  },
  {
    path: '/apps/customer/:id',
    name: 'loyalty-customer-profile',
    component: () => import('@/views/apps/pages/CustomerProfile.vue')
  },

  {
    path: '/apps/billing',
    name: 'loyalty-billing',
    component: () => import('@/views/apps/pages/invoices.vue')
  },
  {
    path: '/apps/invoice/:id',
    name: 'loyalty-billing',
    component: () => import('@/views/apps/pages/invoiceDetails.vue')
  },
  {
    path: '/apps/users',
    name: 'administration-users',
    component: () => import('@/views/apps/pages/users.vue')
  },
  {
    path: '/apps/settings',
    name: 'administration-settings',
    component: () => import('@/views/apps/pages/settings.vue')
  },
  {
    path: '/apps/comingSoon',
    name: 'coming-soon',
    component: () => import('@/views/apps/pages/ComingSoon.vue')
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/views/apps/email/Email.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*

  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },


  // Invoice
  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  }
]
