import axios from "../../axios"

const domain = process.env.VUE_APP_BE_DOMAIN;

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

export default {

    getProducts(){
        return axios.get(domain+"products")
    },
    getSalesStats(){
        return axios.get(domain+"sales")
    },
    getOrderStats(startDate, endDate){
        return axios.get(domain+"orderStats/"+startDate+"/"+endDate);

    },
    getSalesOrders(orderStatus, orderDate, deliveryDate, page){
        return axios.get(domain+"orders/"+orderStatus+"/"+orderDate+"/"+deliveryDate+"?page="+page);
    },
    forgotPassword(userData){
        return axios.post(domain+"forgotPassword", userData)
    },

    getLocations(){
        return axios.get(domain+"locations")
    },

    getBankTransactions(startDate, endDate, page){
        return axios.get(domain+"bankTransactions/"+startDate+"/"+endDate+"?page="+page);
    },
    searchBankTransactions(keyword){
        return axios.get(domain+"searchBankTransactions/"+keyword);
    },
    updateBankTransaction(bankTransactionId){

        return axios.get(domain+"updateBankTransaction/"+bankTransactionId);
    },

    updateSalesOrder(salesOrderData){
        return axios.post(domain+"updateSalesOrder", salesOrderData)
    },

    getTaxRates(){
        return axios.get(domain+"taxRates");
    },

    createLocation(locationData){
        return axios.post(domain+"createLocation", locationData);
    },
    editProduct(productData){
        return axios.post(domain+"editProduct", productData)
    },
    receivePayment(paymentData){
        return axios.post(domain+"receivePayment", paymentData)
    },
    getQuotationStats(startDate, endDate){
        return axios.get(domain+"quotationStats/"+startDate+"/"+endDate);
    },
    saveProduct(productData){
        return axios.post(domain+"saveProduct", productData)
    },
    createQuotation(quotationData){
        return axios.post(domain+"createQuotation", quotationData)
    },

    getQuotations(page, quotationStatus, startDate, endDate){
        return axios.get(domain+"quotations/"+quotationStatus+"/"+startDate+"/"+endDate+"?page="+page)
    },
    getTeamPerformance(startDate, endDate){
        return axios.get(domain+"teamPerformance/"+startDate+"/"+endDate)
    },
    getQBSettings(){
        return axios.get(domain+"qbSettings")
    },
    getSalesCommission(){
        return axios.get(domain+"salesCommission");
    },
    saveSalesCommission(salesCommissionData){
        return axios.post(domain+"saveSalesCommission", salesCommissionData)
    },
    searchQuotationsByCustomerName(name){
        return axios.get(domain+"searchQuotationsByCustomerName/"+name)
    },
    getDeliveryFees(){
        return axios.get(domain+"deliveryFees");
    },

    getInvoices(page){
        console.log("page: "+page)
        return axios.get(domain+"invoices?page="+page);
    },

    saveProductPriceList(productPriceListData){
        return axios.post(domain+"saveProductPriceList", productPriceListData)
    },
    editProductPriceList(productPriceListData){
        return axios.post(domain+"editProductPriceList", productPriceListData)
    },
    getProductPriceList(){
        return axios.get(domain+"productPriceList");
    },

    saveDeliveryFees(deliveryFee){
        return axios.post(domain+"saveDeliveryFee", deliveryFee)
    },

    deleteDeliveryFees(deliveryFeeId){
        return axios.get(domain+"deleteDeliveryFee/"+deliveryFeeId);
    },

    saveQBSettings(qbData){
        return axios.post(domain+"saveQBSettings", qbData)
    },
    deleteProduct(productId){
        return axios.get(domain+"deleteProduct/"+productId)
    },
    deleteQuotation(quotationId){
        return axios.get(domain+"deleteQuotation/"+quotationId)
    },
    getMpesaTransactions(startDate, endDate, page){
        return axios.get(domain+"mpesaTransactions/"+startDate+"/"+endDate+"?page="+page)
    },
    searchMpesaTransactions(transId){
        return axios.get(domain+"searchByTransId/"+transId)
    },
    getProductFinishes(){
        return axios.get(domain+"productFinishes")
    },
    editProductFinishes(productFinishesData){
        return axios.post(domain+"editProductFinishes", productFinishesData)
    },
    editQuotation(quotationData){
        return axios.post(domain+"editQuotation", quotationData)
    },
    initiateSTKPush(paymentData){
        return axios.post(domain+"initiateSTKPush", paymentData)
    },
    saveProductFinishes(productFinishesData){
        return axios.post(domain+"saveProductFinishes", productFinishesData)
    },

    deleteProductFinishes(productFinishesId){
        return axios.get(domain+"deleteProductFinishes/"+productFinishesId)
    },
    getQuotationDetails(quotationId){
        return axios.get(domain+"quotation/"+quotationId)
    },

    createInvoiceFromQuotation(quotation){
        return axios.post(domain+"createInvoiceFromQuotation",quotation)
    },

    getInventoryItems(){
        return axios.get(domain+"inventoryItems")
    },
    saveItemGroup(productData){
        return axios.post(domain+"saveItemGroup", productData)
    },
    editItemGroup(productData){
        return axios.post(domain+"editItemGroup", productData)
    },
    createCustomer(customerData){
        return axios.post(domain+"createCustomer", customerData)
    },
    getCustomers(){
        return axios.get(domain+"customers")
    },
    createRole(roleData){
        return axios.post(domain+"createRole", roleData)
    },
    getRoles(){
        return axios.get(domain+"roles")
    },
    updateRole(roleData){
        return axios.post(domain+"updateRole", roleData)
    },
    getRole(roleId){
        return axios.get(domain+"getRole/"+roleId)
    },
    getPettyCashBudgets(){
        return axios.get(domain+"pettyCashBudgets")

    },
    createPettyCashBudget(pettyCashBudget){
        return axios.post(domain+"createPettyCashBudget", pettyCashBudget)
    },
    saveUser(usersData){
        return axios.post(domain+"saveUser", usersData)
    },
    editUser(usersData){
        return axios.post(domain+"editUser", usersData)
    },
    getUsers(){
        return axios.get(domain+"users")
    },
    deleteRole(roleId){
        return axios.get(domain+"deleteRole/"+roleId)
    },

    getPettyCash(pettyCashStatus,startDate,endDate,supplierId, page){
        return axios.get(domain+"pettyCash/"+pettyCashStatus+"/"+startDate+"/"+endDate+"/"+supplierId+"?page="+page)
    },

    editPettyCash(pettyCashData){
        return axios.post(domain+"editPettyCash", pettyCashData)
    },

    createPettyCash(pettyCashData){
        return axios.post(domain+"createPettyCash", pettyCashData)
    },
    updatePettyCashStatus(pettyCashData){
        return axios.post(domain+"updatePettyCashStatus", pettyCashData)
    },

    createSupplier(supplierData){
        return axios.post(domain+"addSupplier", supplierData)
    },

    editSupplier(supplierData){
        return axios.post(domain+"editSupplier", supplierData)
    },
    deleteSupplier(supplierId){
        return axios.get(domain+"deleteSupplier/"+supplierId)
    },
    getSuppliers(){
        return axios.get(domain+"suppliers")
    },
    getPettyCashSuppliers(supplierId){
        return axios.get(domain+"pettyCashSuppliers/"+supplierId)
    },
    getAllSMS(){
        return axios.get(domain+"getAllSMS");
    },
    getShortCodeRecipients(){
        return axios.get(domain+"shortCodeRecipient");
    },
    getShortCodeChat(recipient){
        return axios.get(domain+"shortCodeChatMessages/"+recipient);
    },
    sendShortCodeMessage(messageData){
        return axios.post(domain+"sendShortMessage", messageData)
    },

    addLead(leadData){
        return axios.post(domain+"addLead", leadData);
    },

    editLead(leadData){
        return axios.post(domain+"editLead", leadData);
    },

    deleteLead(leadId){
        return axios.get(domain+"deleteLead/"+leadId);
    },

    getLeads(startDate,endDate,leadStatus,leadSource,responsible,levelOfInterest, page){
        return axios.get(domain+"leads/"+startDate+"/"+endDate+"/"+leadStatus+"/"+leadSource+"/"+responsible+"/"+levelOfInterest+"?page="+page)
    },

    addLeadSource(leadSourceData){
        return axios.post(domain+"addLeadSource", leadSourceData);
    },

    editLeadSource(leadSourceData){
        return axios.post(domain+"editLeadSource", leadSourceData);
    },

    deleteLeadSource(leadSourceId){
        return axios.get(domain+"deleteLeadSource/"+leadSourceId);
    },

    getLeadSources(){
        return axios.get(domain+"leadSources")
    },
    exportCustomers(emailAddress) {
        return axios.get(domain+"exportCustomers/"+emailAddress)
    },
    updateLeadStatus(leadSourceData) {
        return axios.post(domain+"updateLeadStatus", leadSourceData);
    },
    getLeadStats(startDate, endDate) {
        return axios.get(domain+"/leadStats/"+startDate+"/"+endDate);
    },
    uploadFile(file){
        return axios.post(domain+"uploadFile", file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}
