import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
    state: {

      loading: false,
      error: null,
        locations:{
            id:0,
            locationName:"",
            county:""
        }
    },

    getters: {

    },
    mutations: {
        SET_LOCATIONS(state, locations){
            //console.log(customers);
            state.locations = locations;
        }
    },

  actions: {

      createRole({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.createRole(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      updateRole({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.updateRole(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      deleteRole({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.deleteRole(data.id)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getRoleById({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.getRole(data.id)

                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

        getRoles({commit}){
            return new Promise((resolve,reject) => {
                httpService.getRoles()

                    .then(response => {

                        resolve(response.data);

                    })
                    .catch(error => {
                        reject(error)

                        //reject({message: axios.defaults.headers})
                    })
            })
        },

      getLocations({commit}){
          return new Promise((resolve,reject) => {
              httpService.getLocations()
                  .then(response => {

                      /*response.data.push({
                          id:0,
                          locationName:"Add New Location",
                          county:""
                      });

                      commit("SET_LOCATIONS", response.data);*/

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getQBSettings({commit}){
          return new Promise((resolve,reject) => {
              httpService.getQBSettings()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      saveDeliveryFees({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.saveDeliveryFees(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      deleteDeliveryFees({commit}, data){
          return new Promise((resolve,reject) => {
              httpService.deleteDeliveryFees(data.id)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },


      getDeliveryFees({commit}){
          return new Promise((resolve,reject) => {
              httpService.getDeliveryFees()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      getSalesCommission({commit}){
          return new Promise((resolve,reject) => {
              httpService.getSalesCommission()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },


      saveSalesCommission({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.saveSalesCommission(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },

      saveQBSettings({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.saveQBSettings(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      createLocation({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.createLocation(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      addLeadSource({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.addLeadSource(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      editLeadSource({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.editLeadSource(data)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      deleteLeadSource({commit}, data){
          return new Promise((resolve,reject) => {

              httpService.deleteLeadSource(data.id)
                  .then(response => {

                      // If there's user data in response
                      if(response.data.statusCode == 200){

                          resolve()

                          //this.dispatch('campaigns/getCampaigns')

                      }
                      else{
                          reject({message: response.data.message})
                      }

                  })
                  .catch(error => { reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      },
      getLeadSources({commit}){
          return new Promise((resolve,reject) => {
              httpService.getLeadSources()
                  .then(response => {

                      resolve(response.data);

                  })
                  .catch(error => {
                      reject(error)

                      //reject({message: axios.defaults.headers})
                  })
          })
      }
  }
};
