import httpService from "../../http/requests/app"

const domain = "http://localhost:10443/auth/";

export default {
  state: {

  },

  getters: {

  },
  mutations: {

  },

  actions: {

    createSMS({commit}, smsData){
      return new Promise((resolve,reject) => {
        httpService.createSMS(smsData)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }



          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },

    deleteCampaign({commit}, smsData){
      return new Promise((resolve,reject) => {
        httpService.deleteCampaign(smsData.id)
          .then(response => {

            if(response.data.statusCode == 200){

              resolve()

              //this.dispatch('campaigns/getCampaigns')

            }
            else{
              reject({message: response.data.message})
            }



          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },

    getSMSCampaigns({commit}){

      return new Promise((resolve,reject) => {
        httpService.getSMSCampaigns()
          .then(response => {

            resolve(response.data);


          })
          .catch(error => {

            //reject({message: axios.defaults.headers})
          })
      });
    },
      getPushNotificationCampaigns({commit}){
          return new Promise((resolve,reject) => {
              httpService.getPushNotificationCampaigns()
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      getShortCodeMessages({commit}){

          return new Promise((resolve,reject) => {
              httpService.getAllSMS()
                  .then(response => {

                      resolve(response.data);


                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      },
      uploadFile({commit}, data) {
          return new Promise((resolve,reject) => {
              httpService.uploadFile(data)
                  .then(response => {
                      resolve(response.data)
                  })
                  .catch(error => {

                      //reject({message: axios.defaults.headers})
                  })
          });
      }
  }
};
